import React, { useCallback } from 'react';
import ReactGA from 'react-ga4';
import { faLinkedin, faSquareInstagram, faSquareXTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import cn from './Footer.module.scss';

export const Footer = () => {
  const year = new Date().getFullYear();

  const onClickCognitivLogo = useCallback(() => {
    window.open('https://cognitiv.ai/', '_blank');
    ReactGA.event({
      category: 'user interaction',
      action: 'click',
      label: 'Open Cognitiv Homepage',
    });
  }, []);

  const onClickSocial = useCallback((to: string) => {
    ReactGA.event({
      category: 'user interaction',
      action: `click`,
      label: `Open Cognitiv ${to}`,
    });
  }, []);

  return (
    <div className={cn.footer}>
      <div className={cn.brand}>
        <p className={cn.powered_by}>Powered by</p>
        <img className={cn.logo} src="/cognitiv-logo-white.png" alt="cognitiv logo" onClick={onClickCognitivLogo} />
      </div>
      <div className={cn.info}>
        <div className={cn.socials}>
          <a
            href="https://www.linkedin.com/company/the-positiv-ai/"
            aria-label="linkedin"
            target="_blank"
            rel="noreferrer"
            onClick={() => onClickSocial('LinkedIn')}
          >
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
          <a href="https://x.com/thepositiv_ai" aria-label="x" target="_blank" rel="noreferrer" onClick={() => onClickSocial('X')}>
            <FontAwesomeIcon icon={faSquareXTwitter} />
          </a>
          <a
            href="https://www.instagram.com/thepositiv.ai/?hl=en"
            aria-label="instagram"
            target="_blank"
            rel="noreferrer"
            onClick={() => onClickSocial('instagram')}
          >
            <FontAwesomeIcon icon={faSquareInstagram} />
          </a>
        </div>
        <p className={cn.copyrights}>{year} Cognitiv. All rights reserved</p>
      </div>
    </div>
  );
};

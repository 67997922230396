import React, { useCallback, useState } from 'react';
import ReactGA from 'react-ga4';

import { Article } from '../articles/Articles';

import cn from './ArticleTopFive.module.scss';

export const ArticleTopFive = ({ article, place }: { article: Article; place: number }) => {
  const { Title, Description, Images, SiteName, Url, DaysOnList } = article;
  const [usePlaceholder, setUsePlaceholder] = useState(false);

  const onClickArticle = useCallback(() => {
    ReactGA.event({
      category: 'user interaction',
      action: 'click',
      label: `${Title}: ${Url}`,
    });
  }, [Title, Url]);

  const handleImageError = () => {
    setUsePlaceholder(true);
  };

  return (
    <div className={cn.card}>
      <a href={Url} aria-label={Title} target="_blank" rel="noreferrer" onClick={onClickArticle} className={cn.img_container}>
        <div
          className={cn.img}
          style={{
            backgroundImage: `url("${usePlaceholder ? '/cognitiv-brain.svg' : Images[0]}")`,
            backgroundSize: usePlaceholder ? '50%' : 'cover',
            backgroundColor: usePlaceholder ? '#c6d3e8' : '',
            backgroundPosition: usePlaceholder ? 'center' : 'top',
          }}
          role="img"
          aria-label={Title}
        />
      </a>
      <img src={Images[0]} alt={Title} style={{ display: 'none' }} onError={handleImageError} />
      <div className={cn.info_container}>
        <p className={cn.place}>{place}</p>
        <div className={cn.info}>
          <div className={cn.header}>
            <p className={cn.place_mobile}>{place}</p>
            <a href={Url} aria-label={Title} target="_blank" rel="noreferrer" onClick={onClickArticle}>
              <h3 className={cn.title} title={Title}>
                {Title}
              </h3>
            </a>
          </div>
          <a href={Url} aria-label={Description} target="_blank" rel="noreferrer" onClick={onClickArticle} className={cn.description}>
            {Description}
          </a>
          <a
            href={Url}
            aria-label={`${SiteName} - ${DaysOnList} Days on List`}
            target="_blank"
            rel="noreferrer"
            onClick={onClickArticle}
            className={cn.source}
          >
            <span className={cn.site_name}>{SiteName}</span> {DaysOnList && ` (${DaysOnList} Days on List)`}
          </a>
        </div>
      </div>
    </div>
  );
};

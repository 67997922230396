import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga4';

import { ArticleCard } from '../article-card/ArticleCard';
import { ArticleTopFive } from '../article-top-five/ArticleTopFive';
import { SubscribeCard } from '../subscribe-card/SubscribeCard';

import cn from './Articles.module.scss';

const INITIAL_ARTICLE_COUNT = 16;
const LOAD_MORE_COUNT = 6;

export interface Article {
  Description: string;
  Domain: string;
  Duration: string;
  Favicon: string;
  Images: string[];
  SiteName: string;
  Title: string;
  Url: string;
  UrlHash: number;
  DaysOnList: number;
}

export const Articles = () => {
  const [data, setData] = useState<Article[]>([]);
  const [count, setCount] = useState(INITIAL_ARTICLE_COUNT);
  const [hasMore, setHasMore] = useState(false);

  const articles = useMemo(() => data.slice(0, count), [count, data]);

  useEffect(() => {
    const fetchCSV = async () => {
      try {
        const res = await fetch(`${process.env.REACT_APP_AWS}/url_metadata.json`).then((data) => data.json());
        setData(res);
        setHasMore(res.length > INITIAL_ARTICLE_COUNT);
      } catch (error) {
        console.error('Error fetching CSV data:', error);
      }
    };

    fetchCSV();
  }, []);

  const onClick = useCallback(() => {
    const newCount = count + LOAD_MORE_COUNT;
    setCount(newCount);

    ReactGA.event({
      category: 'user interaction',
      action: 'click',
      label: 'Load More Articles',
    });

    if (newCount >= data.length) {
      setHasMore(false);
    }
  }, [count, data.length]);

  return (
    <>
      {!data.length && <p className={cn.no_news}>No news is good news :)</p>}
      {!!data.length && (
        <div className={cn.articles}>
          <div className={cn.topfive}>
            {articles.slice(0, 5).map((article, index) => (
              <ArticleTopFive key={index} article={article} place={index + 1} />
            ))}
          </div>
          <div className={cn.grid}>
            {articles.slice(5).map((article, index) => (
              <ArticleCard key={index} article={article} place={index + 6} />
            ))}
            <SubscribeCard />
          </div>
          {hasMore && (
            <button className={cn.button} onClick={onClick}>
              LOAD MORE
            </button>
          )}
        </div>
      )}
    </>
  );
};

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { faArrowDown, faArrowUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import cn from './Header.module.scss';

export interface Ratio {
  Positive: number;
  Negative: number;
  Neutral: number;
}

export const Header: React.FC = () => {
  const [top, setTop] = useState(-64);
  const lastScrollTop = useRef(-64);
  const [data, setData] = useState<Ratio | null>(null);

  useEffect(() => {
    const fetchCSV = async () => {
      try {
        const res = await fetch(`${process.env.REACT_APP_AWS}/ratio_data.json`).then((data) => data.json());
        const [today] = res;
        const normalized = {
          Positive: Math.round((today.Positive / today.Total) * 1000) / 10,
          Negative: Math.round((today.Negative / today.Total) * 1000) / 10,
          Neutral: Math.round((today.Neutral / today.Total) * 1000) / 10,
        };
        setData(normalized);
      } catch (error) {
        console.error('Error fetching CSV data:', error);
      }
    };

    fetchCSV();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const currentScroll = window.pageYOffset || document.documentElement.scrollTop;
      const headerHeight = document.getElementById('header')?.offsetHeight || -64;

      if (currentScroll > lastScrollTop.current) {
        setTop((prev) => Math.min(prev + (currentScroll - lastScrollTop.current), headerHeight + 64));
      } else {
        setTop((prev) => Math.max(prev - (lastScrollTop.current - currentScroll), 0));
      }

      lastScrollTop.current = currentScroll;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const onClickPositivLogo = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <div id="header" className={cn.header} style={{ top: `-${top}px` }}>
      <img className={cn.logo} src="/cognitiv-positiv-logo.svg" alt="cognitiv positiv logo" onClick={onClickPositivLogo} />
      {!!data && (
        <div className={cn.pulse}>
          <div className={cn.title_container}>
            <p className={cn.title}>Positiv News Pulse</p>
            <div className={cn.info}>
              <FontAwesomeIcon icon={faInfoCircle} />
              <div className={cn.tooltip}>
                <FontAwesomeIcon icon={faInfoCircle} />
                <p>
                  The Positiv News Pulse shows the daily ratio of positive and negative news identified by our AI, with all other content classified
                  as neutral. This tracker showcases our AI's ability to understand content and measure the balance of sentiment in online news,
                  highlighting the need for more uplifting stories in today's digital landscape.
                </p>
              </div>
            </div>
          </div>
          <div className={cn.ratio_container}>
            <div className={cn.ratio}>
              <div className={cn.arrow}>
                <FontAwesomeIcon icon={faArrowUp} color="#5CB85C" />
              </div>
              <p>{data.Positive}%</p>
            </div>
            <div className={cn.ratio}>
              <p>{data.Negative}%</p>
              <div className={cn.arrow}>
                <FontAwesomeIcon icon={faArrowDown} color="#D9534F" />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

import React from 'react';

import cn from './Banner.module.scss';

export const Banner = () => {
  return (
    <div className={cn.banner}>
      <div className={cn.container}>
        <h1 className={cn.title}>AI Bringing You Brighter News</h1>
        <p className={cn.description}>
          Welcome to a brighter corner of the internet. Our mission is to highlight positive and inspiring stories that often go unnoticed. Utilizing
          Cognitiv AI, we curate feel-good news that showcases resilience, compassion, and creativity from communities around the world. Let's
          celebrate the goodness that surrounds us.
        </p>
      </div>
    </div>
  );
};
